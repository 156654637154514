import React from "react";
import ImgBack from "../../Assets/backtruck.png";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const Service4 = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Warehousing</h1>
          <p className="primary-text">
            At FletEx Trucking, we provide a robust network of strategically
            located facilities to meet your supply chain needs across the United
            States and Mexico. Our warehousing solutions are versatile, ranging
            from customized options to turnkey services, including specialized
            warehousing and immediate occupancy. Our dedicated warehousing
            experts actively manage every aspect, from scheduling to process
            control and invoice auditing, ensuring a streamlined experience.
          </p>
          <Link to={"/services"} className="secondary-button2">
            Services <FiArrowRight />
          </Link>
        </div>
        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service4;
