import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

import Home from "./Components/Home";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Modes from "./Components/OurModes";
import Services1 from "./Components/Services/Service1";
import Services2 from "./Components/Services/Service2";
import Services3 from "./Components/Services/Service3";
import Services4 from "./Components/Services/Service4";
import Services5 from "./Components/Services/Service5";
import Services6 from "./Components/Services/Service6";
import Services7 from "./Components/Services/Service7";
import Navbar from "./Components/Navbar";

function Content() {
  return (
    <div>
      <Home />
      <Modes />
      <Work />
      <Testimonial />
      <Contact />
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Content />} />
            <Route path="/content" element={<Content />} />
            <Route path="/modes" element={<Modes />} />
            <Route path="/services" element={<Work />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/service/drop-trailer" element={<Services1 />} />
            <Route path="/service/overweight" element={<Services2 />} />
            <Route path="/service/partials" element={<Services3 />} />
            <Route path="/service/warehousing" element={<Services4 />} />
            <Route path="/service/customs" element={<Services5 />} />
            <Route path="/service/mexico" element={<Services6 />} />
            <Route path="/service/final-mile" element={<Services7 />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
