import React from "react";
import ImgBack from "../Assets/back.png";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Logistic and Brokerage Services</h1>
          <p className="primary-text">
            Your trusted partner in Logistics Excellence
          </p>
        </div>
        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
