import React from "react";
import ImgBack from "../../Assets/mile.png";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const Service7 = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Final Mile</h1>
          <p className="primary-text">
            FletEx Trucking's Final Mile services seamlessly integrate our
            round-the-clock support with direct delivery to your business
            locations. Our comprehensive first and last mile solutions offer
            tailored support that adapts to your delivery model and pricing
            requirements.
          </p>
          <Link to={"/services"} className="secondary-button2">
            Services <FiArrowRight />
          </Link>
        </div>
        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service7;
