import React from "react";
import Semi from "../Assets/semi.png";
import Heavy from "../Assets/heavy.png";
import Less from "../Assets/less.svg";
import Customs from "../Assets/customs.png";
import Mexico from "../Assets/mexico.png";
import Final from "../Assets/final.png";
import Warehouse from "../Assets/warehouse.png";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const Work = () => {
  const workInfoData = [
    {
      image: Semi,
      title: "Drop Trailer",
      text: "Efficiently manage your shipments with our Drop Trailer service. We provide secure and convenient options for loading and unloading, ensuring a seamless transportation experience.",
      url: "drop-trailer",
    },
    {
      image: Heavy,
      title: "OverWeight Oversize",
      text: "Handle heavy and oversized cargo confidently with our OverWeight Oversize service. We specialize in transporting large and challenging shipments, offering reliable solutions for your unique requirements.",
      url: "overweight",
    },
    {
      image: Less,
      title: "Partials",
      text: "Optimize your logistics with our Partials service. We offer flexible and cost-effective solutions for partial shipments, ensuring you only pay for the space you need.",
      url: "partials",
    },

    {
      image: Mexico,
      title: "Mexico",
      text: "Simplify your shipments to and from Mexico with our specialized Mexico service. Benefit from our expertise in cross-border logistics, ensuring timely and reliable deliveries.",
      url: "mexico",
    },
    {
      image: Customs,
      title: "Customs",
      text: "Navigate international shipping seamlessly with our Customs service. We provide expert assistance in navigating customs regulations, ensuring a smooth and compliant cross-border experience.",
      url: "customs",
    },
    {
      image: Final,
      title: "Final Mile",
      text: "Ensure the last leg of your shipment is as smooth as the first with our Final Mile service. We focus on precision and efficiency to guarantee on-time deliveries to their ultimate destination.",
      url: "final-mile",
    },
    {
      image: Warehouse,
      title: "Warehousing",
      text: "Ensure the last leg of your shipment is as smooth as the first with our Final Mile service. We focus on precision and efficiency to guarantee on-time deliveries to their ultimate destination.",
      url: "warehousing",
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">Specialized Services</h1>
        <p className="primary-text">
          Discover our tailored solutions for Drop Trailer, OverWeight Oversize,
          Partials, Customs, Mexico, and Final Mile services. Simplifying
          logistics, ensuring compliance, and delivering excellence for your
          unique shipping requirements.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data, index) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" style={{ width: "8rem" }} />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            <Link to={`/service/${data.url}`} className="secondary-button2">
              View more <FiArrowRight />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
