import React from "react";
import ImgBack from "../../Assets/map.png";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const Service6 = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Mexico Cross-Border</h1>
          <p className="primary-text">
            At FletEx Trucking, our dedicated team of bilingual logistics
            experts stands as your unwavering partners, guiding you through the
            intricacies of cross-border Mexico shipments to guarantee safe and
            punctual deliveries.
          </p>
          <Link to={"/services"} className="secondary-button2">
            Services <FiArrowRight />
          </Link>
        </div>
        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service6;
