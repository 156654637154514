import React from "react";

const Contact = () => {
  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Have Question In Mind?</h1>
      <h1 className="primary-heading">Contact us</h1>
      <div className="testimonial-section-bottom">
        <h2>sales@fletextrucking.com</h2>
      </div>
    </div>
  );
};

export default Contact;
