import React from "react";
import Truck from "../Assets/truck.png";
import Air from "../Assets/air.png";
import Less from "../Assets/less.svg";
import Containers from "../Assets/containers.png";

const Work = () => {
  const workInfoData = [
    {
      image: Truck,
      title: "Full Truckload",
      text: "Optimize your shipping with our Full Truckload services. We ensure efficient transportation, managing every element with ease and precision.",
    },
    {
      image: Air,
      title: "Air",
      text: "Experience swift and secure deliveries through our Air freight services. We prioritize speed without compromising on the safety and reliability of your shipments.",
    },
    {
      image: Less,
      title: "Less Than Truckload",
      text: "Streamline your logistics with our Less Than Truckload solutions. We offer flexibility and cost-effectiveness for shipments that don't require a full truckload.",
    },
    {
      image: Containers,
      title: "Drayage",
      text: "Efficiently move your cargo over short distances with our Drayage services. We provide seamless and reliable transportation solutions tailored to your specific needs.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Our Modes</p>
        <h1 className="primary-heading">How We Work</h1>
        <p className="primary-text">
          In FletexTrucking, we have carefully designed our services to meet
          your diverse shipping needs. Explore our four distinctive modes of
          operation, each tailored to provide optimal solutions for your cargo
          transportation.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" style={{ width: "10rem" }} />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
