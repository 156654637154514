import React from "react";
import ImgBack from "../../Assets/custom.png";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const Service5 = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Customs Brakerage</h1>
          <p className="primary-text">
            Within our extensive range of international shipment services,
            FletEx Trucking proudly provides customs brokerage services for
            shipments entering the United States. We hold the prestigious
            certification as a member of the Customs Trade Partnership Against
            Terrorism (CTPAT) program, underscoring our commitment to ensuring
            the utmost security and compliance.{" "}
          </p>
          <Link to={"/services"} className="secondary-button2">
            Services <FiArrowRight />
          </Link>
        </div>
        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service5;
