import React from "react";
import ImgBack from "../../Assets/partial.png";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const Service3 = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Partials</h1>
          <p className="primary-text">
            When you have cargo occupying 12' to 40' of space and weighing under
            36,000 lbs, trust FletEx Trucking's Partials Department. We
            specialize in flexible solutions for shipments that don't fit
            traditional Less than Truckload (LTL) requirements, efficiently
            consolidating your freight into single multi-stop trailers or box
            trucks.{" "}
          </p>
          <Link to={"/services"} className="secondary-button2">
            Services <FiArrowRight />
          </Link>
        </div>
        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service3;
