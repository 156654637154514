import React from "react";
import ImgBack from "../../Assets/kg.png";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const Service2 = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">OverWeight Oversize</h1>
          <p className="primary-text">
            At FletEx Trucking, we possess an in-depth understanding of the
            complexities associated with transporting oversize and overweight
            freight. Our committed team of experts boasts the capability to
            oversee large-scale projects, taking full charge of every facet of
            transportation on your behalf.
          </p>
          <Link to={"/services"} className="secondary-button2">
            Services <FiArrowRight />
          </Link>
        </div>
        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service2;
