import React from "react";
import ImgBack from "../../Assets/drop.png";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const Service1 = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src="" alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Drop Trailer</h1>
          <p className="primary-text">
            At FletEx Trucking, our team of drop trailer specialists excels at
            identifying your supply chain pain points and providing tailored
            solutions to significantly enhance efficiency through our Drop
            Trailer Program.
          </p>
          <Link to={"/services"} className="secondary-button2">
            Services <FiArrowRight />
          </Link>
        </div>

        <div className="home-image-section">
          <img src={ImgBack} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service1;
