/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "../Assets/output-onlinepngtools (1).png";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
      url: "/",
    },
    {
      text: "Our modes",
      icon: <CommentRoundedIcon />,
      url: "/modes",
    },
    {
      text: "Contact",
      icon: <PhoneRoundedIcon />,
      url: "/contact",
    },
    {
      text: "Services",
      icon: <InfoIcon />,
      url: "/services",
    },
  ];
  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" style={{ width: "20rem" }} />
      </div>
      <div className="navbar-links-container">
        <Link to="/">Home</Link>
        <Link to="/modes">Our Modes</Link>
        <Link to="/services">Services</Link>
        <Link to="/contact">Contact</Link>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 200 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List style={{ marginLeft: "10px", marginTop: "20px" }}>
            {menuOptions.map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                style={{ marginTop: "20px" }}
              >
                <Link
                  to={item.url}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </Link>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
